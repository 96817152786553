import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SearchBar from '../../../components/utils/SearchBar'
import Table from '../../../components/utils/Table'
import Tag from '../../../components/utils/Tag'

const InventoryViewDetails = ({ back }) => {
    const [filterText, setFilterText] = useState("");
    const { inventoryViewDetails, inventoryViewDetailsLoading } = useSelector((state) => state.portinInventory);
    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'SERIAL NUMBER', key: 'serialNumber' },
        { displayName: 'STATUS	', key: 'status' },
    ];

    const filteredItems = Array.isArray(inventoryViewDetails) && inventoryViewDetails.length > 0 ? inventoryViewDetails.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];


    const dataSource = filteredItems && filteredItems.map((item, key) => {
        return {
            key: key + 1,
            serialNumber: item.SerialNumber,
            status: <Tag color={`${item.StatusDescription === "Free" ? "badge-success" : item.StatusDescription === "Under Processing" ? "badge-warning" : item.StatusDescription === "Used" ? "badge-secondary" : item.StatusDescription === "Active" ? "badge-secondary" : "badge-danger"
                }`} title={item.StatusDescription} />
        }
    });
    return (
        <>
            <div className="row">
                <div className='col-lg-6 col-md-6 od-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
                <div className='col-lg-6 col-md-6 text-right'>
                    <button type="button" className="btn btn-primary mr-2" onClick={() => back()}>
                        <i className="fa fa-arrow-left mr-2"></i> Back
                    </button>

                </div>
            </div>
            <Table columns={columns} loading={inventoryViewDetailsLoading} data={dataSource} pagination={true} />
        </>

    )
}

export default InventoryViewDetails
