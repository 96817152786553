import React, { useState, useRef, useEffect, useMemo } from 'react';
import CardLayout from '../../../components/utils/CardLayout';
import { useDispatch, useSelector } from 'react-redux';
import networkActions from '../../../redux/network/actions';
import { useForm } from 'react-hook-form';
import portinActivationAction from '../../../redux/portinActivation/actions'
import shortCodeAction from '../../../redux/shortCodeData/actions';
import Button from '../../../components/utils/Button'
import Table from '../../../components/utils/Table';
import SideButtons from '../../../components/utils/SideButtons';
import Alert from '../../../components/utils/Alert';
import SearchBar from '../../../components/utils/SearchBar';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import { convertDateTimeToRequiredFormatTZ, twoDigitDecimal, isAdmin, isAdminAgent, isDealer, validateZipCode } from '../../../helpers/commonFunction';
import Tag from '../../../components/utils/Tag'
import authActions from '../../../redux/auth/actions';

const { getBulkPortinActivation, getNetworkByDealerPortinMapping, resetMessage, createPortinActivation, resetData } = portinActivationAction;
const { getActiveNetwork } = networkActions;
const { getSimType } = shortCodeAction;
const { getUserDataByToken } = authActions;

const PortinActivation = () => {
  const dispatch = useDispatch();
  const { bulkPortinActivationList, portinNetworkList, bulkPortinActivationLoading, createPortinActivationLoading, createPortinActivationMessage, createPortinActivationError } = useSelector((state) => state.portinActivation);
  const { shortCodeSimType } = useSelector((state) => state.shortCode);
  const { tokenData } = useSelector((state) => state.auth);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [filterText, setFilterText] = useState("");
  const [addPortinFlag, setAddPortinFlag] = useState(false);
  const [searchData, setSearchData] = useState({
    networkID: '',
    simType: 25,
    zipCode: "",
    quantity: "",
  });

  const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ defaultValues: searchData });

  useEffect(() => {
    dispatch(getActiveNetwork());
    dispatch(getNetworkByDealerPortinMapping())
    dispatch(getSimType({
      tableName: "activationrequest",
      fieldName: "SimType"
    }));
    dispatch(getBulkPortinActivation());
    alertCancel();
  }, []);

  useEffect(() => {
    if (portinNetworkList && portinNetworkList.length == 1) {
      reset({
        networkID: portinNetworkList[0].NetworkID
      });
    }
  }, [portinNetworkList]);

  useEffect(() => {
    if (createPortinActivationMessage !== null && createPortinActivationMessage !== undefined) {
      displayAlert(createPortinActivationMessage, 'alert-success');
      handleBack()
    }
    if (createPortinActivationError !== null && createPortinActivationError !== undefined) {
      displayAlert(createPortinActivationError, 'alert-danger');
    }
  }, [createPortinActivationMessage, createPortinActivationError]);

  const displayAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
    setShowAlert(true);
    dispatch(getUserDataByToken());
  };

  const alertCancel = () => {
    setShowAlert(false);
    setAlertText('');
    setAlertType('');
    dispatch(resetMessage());
    dispatch(getUserDataByToken());
  }

  const handleFilterChange = (value) => {
    setFilterText(value);
  };

  let columns;
  if ((isAdmin(tokenData) || isAdminAgent(tokenData))) {
    columns = [
      { displayName: '#', key: 'key' },
      { displayName: 'STATUS', key: 'status' },
      { displayName: 'TRANSACTIONID', key: 'transactionID' },
      { displayName: 'SERIAL NUMBER', key: 'serialNo' },
      { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
      { displayName: 'ACCOUNT NUMBER', key: 'accountNumber' },
      { displayName: 'NETWORK', key: 'networkName' },
      { displayName: 'DEALER', key: 'DealerName' },
      { displayName: 'AMOUNT', key: 'amountCharged' },
      { displayName: 'SUBMITTED DATE', key: 'requestedDtTm' },
      { displayName: 'SIM', key: 'simType' },
      { displayName: 'REMARK', key: 'Remark' },
    ];
  } else {
    columns = [
      { displayName: '#', key: 'key' },
      { displayName: 'STATUS', key: 'status' },
      { displayName: 'TRANSACTIONID', key: 'transactionID' },
      { displayName: 'MOBILE NUMBER', key: 'mobileNumber' },
      { displayName: 'ACCOUNT NUMBER', key: 'accountNumber' },
      { displayName: 'NETWORK', key: 'networkName' },
      { displayName: 'DEALER', key: 'DealerName' },
      { displayName: 'AMOUNT', key: 'amountCharged' },
      { displayName: 'SUBMITTED DATE', key: 'requestedDtTm' },
      { displayName: 'SIM', key: 'simType' },
      { displayName: 'REMARK', key: 'Remark' },
    ];

  }

  const filteredItems = Array.isArray(bulkPortinActivationList) && bulkPortinActivationList.length > 0 ? bulkPortinActivationList.filter(item => {
    if (typeof filterText !== 'string' || typeof item !== 'object') return false;
    // Check if any value in the object contains the filterText
    return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
  }) : [];
  const data = filteredItems && filteredItems.length > 0 && filteredItems.map((Item, key) => {
    return {
      key: key + 1,
      transactionID: Item.TransactionID,
      DealerName: Item.DealerName,
      serialNo: Item.SerialNumber,
      mobileNumber: Item.MSISDN,
      accountNumber:Item.AccountNumber,
      networkName: Item.NetworkName,
      simType: Item.SimTypeName,
      amountCharged: Item.AmountCharged != '' && Item.AmountCharged ? '$' + Item.AmountCharged : '',
      requestedDtTm: Item.RequestedDtTm !== null ? convertDateTimeToRequiredFormatTZ(Item.RequestedDtTm) : '',
      status: <Tag color={`${(Item.RequestStatus === 13) ? 'badge-danger' : (Item.RequestStatus === 11 || (Item.RequestStatus >= 18 && Item.RequestStatus <= 22) || Item.RequestStatus === 27) ? 'badge-warning' : (Item.RequestStatus === 12) ? 'badge-success' : (Item.RequestStatus === 32) ? 'badge-info' : 'badge-secondary'}`} title={`${(Item.RequestStatus === 13) ? 'Cancelled' : (Item.RequestStatus === 11 || (Item.RequestStatus >= 18 && Item.RequestStatus <= 22)) ? 'Processing' : (Item.RequestStatus === 12) ? 'Processed' : Item.RequestStatus === 27 ? 'Assigned to Care' : (Item.RequestStatus === 32) ? 'Picked' : 'Pending'}`}> </Tag>,
      remark: Item.Remark,
    }
  });

  const onSubmit = (data) => {
    dispatch(createPortinActivation(data))
  }

  const handleCancel = () => {
    reset()
    dispatch(resetData())
  }

  const handleAddPortinActivation = () => {
    setAddPortinFlag(true)
  }
  const handleBack = () => {
    setAddPortinFlag(false)
    handleCancel()
  }

  return (
    <CardLayout title="Portin Activation">
      {addPortinFlag && <div className='mr-2 row align-items-center justify-content-end  portinBackButton'>
        <SideButtons title="Back" buttonStyle="primary" onClick={handleBack} btnIcon={<ArrowLeftLineIcon />} />
      </div>}
      {!addPortinFlag && <div>
        <div className="row align-items-baseline">
          <div className='col-lg-6 col-md-6  od-2'>
            <SearchBar onFilter={handleFilterChange} filterText={filterText} />
          </div>
          {isDealer(tokenData) ? <div className='col-lg-6 col-md-6  text-right'>
            <SideButtons title="Add Portin" buttonStyle="primary" onClick={handleAddPortinActivation} />
          </div> : ''}
        </div>
        {showAlert && createPortinActivationMessage && <div className='mt-4'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
        <Table columns={columns} loading={bulkPortinActivationLoading} data={data} pagination={true} />
      </div>}

      {addPortinFlag && <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="col-lg-2 col-md-4 mb-2">
            <label className='ml-2'> Network <span className='text-danger'>*</span></label>
            <select
              className="form-control"
              placeholder="Select Network"
              name="networkID"
              {...register("networkID", { required: 'Network is required !' })}
            >
              <option value="" disabled>Select</option>
              {portinNetworkList && portinNetworkList.length > 0 && portinNetworkList.map(network => (
                <option name="network" value={network.NetworkID} key={network.ID}>
                  {network.NetworkName}
                </option>
              ))}
            </select>
            {errors && errors.networkID && (<div className="invalid-feedback">{errors.networkID.message}</div>)}
          </div>

          <div className="col-lg-2 mb-2">
            <label>ZIP Code <span className='text-danger'>*</span></label>
            <input
              type="text"
              placeholder="Enter ZIP Code"
              className={"form-control"}
              name="zipCode"
              {...register("zipCode", {
                required: "ZIP Code is required!",
                validate: (val) => {
                  if (!validateZipCode(val)) {
                    return "Invalid ZIP Code";
                  }
                  return true;
                }
              })}
            />
            {errors && errors.zipCode && (<div className="invalid-feedback">{errors.zipCode.message}</div>)}
          </div>

          <div className="col-lg-2 col-md-4 mb-2">
            <label className='ml-2'> SIM Type <span className='text-danger'>*</span></label>
            <select
              className="form-control"
              name="simType"
              {...register("simType", { required: 'SIM Type is required !' })}
            >
              <option value="" disabled>Select</option>
              {shortCodeSimType && shortCodeSimType.length > 0 && shortCodeSimType.filter(item => item.ID == 25).map((item, ind) => (
                <option name="simType" value={item.ID} key={item.ID}>
                  {item.Value}
                </option>
              ))}
            </select>
            {errors && errors.simType && (<div className="invalid-feedback">{errors.simType.message}</div>)}

          </div>

          <div className="col-lg-2 mb-2">
            <label>Quantity <span className='text-danger'>*</span></label>
            <input
              type="text"
              placeholder="Enter Quantity"
              className={"form-control"}
              name="quantity"
              {...register("quantity", {
                required: "Quantity is required!",
              })}
            />
            {errors && errors.quantity && (<div className="invalid-feedback">{errors.quantity.message}</div>)}
          </div>

          <div className="mt-4 col-lg-2">
            <Button title="Submit" style="minWidth:80px" buttonClass={`btn btn-primary mt-1 btn-md Activity_searchBtn__GlBtC`} buttonType='submit' loading={createPortinActivationLoading} />
          </div>

        </div>

        {showAlert && createPortinActivationError && <div className='mt-4'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
      </form>}
    </CardLayout>
  )
}

export default PortinActivation
