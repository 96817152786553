import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import './assets/css/style.css'
import { AdminRoute, AdminOrAgentRoute, DealerRoute, AdminOrDealerRoute, AdminOrDealerWhoHaveAllowDealerCreationRoute } from "./components/utils/RouteProtect";
import NotFound from "./components/utils/NotFound";
import Header from "./components/layout/Header";
import Footer from './components/layout/Footer';
import Sidebar from './components/layout/Sidebar';
import Signin from "./pages/Signin";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import Dealer from './pages/Admin/Dealer';
import User from './pages/Admin/User';
import ManualAddFund from './pages/Admin/ManualAddFund';
import Network from './pages/Config/Network';
import Aggregator from './pages/Config/Aggregator';
import Plan from './pages/Config/Plan';
import AddonService from './pages/Config/AddonService';
import PlanPriceMapping from './pages/Config/PlanPriceMapping';
import Assets from './pages/Config/Assets';
import ActivationRequest from './pages/Activation/ActivationRequest';
import ActivationReport from './pages/Report/ActivationReport/ActivationReport';
import AssetPerformanceReport from './pages/Report/AssetPerformanceReport/AssetPerformanceReport';
import MyProfile from './pages/Profile/MyProfile'
import AuditReport from './pages/Report/AuditReport/AuditReport'
import TMobileDealerLogin from "./pages/Admin/TMobileDealerLogin";
import AddonPriceMapping from "./pages/Config/AddonPriceMapping";
import Activity from "./pages/Other/Activity/Activity";
import UploadJar from "./pages/Admin/UploadJar";
import ManualActivationReport from './pages/Report/ManualActivationReport/manualActivationReport'
import BulkActivationReport from './pages/Report/BulkActivationReport/BulkActivationReport'
import DealerPlanPriceMapping from "./pages/Config/DealerPlanPriceMapping";
import DealerAddonPriceMapping from "./pages/Config/DealerAddonPriceMapping";
import SimSwapRequest from "./pages/Activation/SimSwapRequest/SimSwapRequest";
import RechargeRequest from "./pages/Activation/RechargeRequest/RechargeRequest";
import RechargePlan from "./pages/Config/RechargePlan/RechargePlan";
import EmailInbox from "./pages/Activation/EmailInbox/EmailInbox";
import IQRequestReport from "./pages/Report/IQRequestReport/IQRequestReport";
import DailyActsPlan from "./pages/Activation/DailyActsPlan/DailyActsPlan";
import DailyPlanRequestReport from "./pages/Report/DailyPlanRequestReport/DailyPlanRequestReport"
import PortinActivation from "./pages/Activation/PortinActivation/PortinActivation";
import PortinInventory from "./pages/Config/PortinInventory/PortinInventory";

function App() {
  const Layout = () => {
    return (
      <>
        <div class="wrapper">
          <Sidebar />
          <Header />
          <div id="content-page" class="content-page">
            <Outlet />
          </div>
        </div>
        <Footer />
      </>
    )
  }


  const isAuthenticated = () => {
    let token = localStorage.getItem("mvno_admin_token");
    if (token) {
      let decodedToken = jwtDecode(token);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.removeItem("mvno_admin_token");
        return false;
      } else {
        return true;
      }
    } else {
      localStorage.removeItem("mvno_admin_token");
      return false;
    }
  }




  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/sign-in" element={isAuthenticated() ? <Navigate to="/" /> : <Signin />} />
          <Route path="/forget-password" element={isAuthenticated() ? <Navigate to="/" /> : <ForgetPassword />} />
          <Route path="/reset-password/*" element={isAuthenticated() ? <Navigate to="/" /> : <ResetPassword />} />

          <Route path="/" element={!isAuthenticated() ? <Navigate to="/sign-in" /> : <Layout />} >

            {/* common route */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/dealer" element={<AdminOrDealerWhoHaveAllowDealerCreationRoute><Dealer /></AdminOrDealerWhoHaveAllowDealerCreationRoute>} />
            <Route path="/user" element={<AdminOrDealerRoute><User /></AdminOrDealerRoute>} />
            <Route path='/my-profile' element={<MyProfile />}></Route>
            <Route path="/plan" element={<AdminOrDealerRoute><Plan /></AdminOrDealerRoute>} />
            <Route path="/addon-service" element={<AdminOrDealerRoute><AddonService /></AdminOrDealerRoute>} />
            <Route path="/activation-request" element={<ActivationRequest />} />
            <Route path="/activation-report" element={<ActivationReport />} />
            <Route path="/bulk-activation-report" element={<BulkActivationReport />} />
            <Route path="/recharge-request" element={<RechargeRequest />} />
            <Route path="/email-inbox" element={<EmailInbox />} />
            <Route path="/iq-request-report" element={<IQRequestReport />} />

            <Route path="/audit-report" element={<AdminOrDealerRoute><AuditReport /></AdminOrDealerRoute>} />
            <Route path="/asset-performance-report" element={<AdminRoute><AssetPerformanceReport /></AdminRoute>} />
            <Route path='/activity-log' element={<Activity />}></Route>
            <Route path='/upload-jar' element={<AdminOrAgentRoute><UploadJar /></AdminOrAgentRoute>}></Route>
            <Route path='/sim-swap-request' element={<SimSwapRequest />}></Route>

            <Route path="/dealer-plan-price-mapping" element={<DealerRoute><DealerPlanPriceMapping /></DealerRoute>} />
            <Route path="/dealer-addon-price-mapping" element={<DealerRoute><DealerAddonPriceMapping /></DealerRoute>} />
            <Route path="/portin-activation" element={<AdminOrDealerRoute><PortinActivation /></AdminOrDealerRoute>} />

            {/* admin route */}
            <Route path="/t-mobile-dealer" element={<AdminRoute><TMobileDealerLogin /></AdminRoute>} />
            <Route path="/manual-add-fund" element={<AdminRoute><ManualAddFund /></AdminRoute>} />
            <Route path='/manual-activation-report' element={<AdminRoute><ManualActivationReport /></AdminRoute>}></Route>
            <Route path="/network" element={<AdminRoute><Network /></AdminRoute>} />
            <Route path="/aggregator" element={<AdminRoute><Aggregator /></AdminRoute>} />
            <Route path="/plan-price-mapping" element={<AdminRoute><PlanPriceMapping /></AdminRoute>} />
            <Route path="/addon-price-mapping" element={<AdminRoute><AddonPriceMapping /></AdminRoute>} />
            <Route path="/asset" element={<AdminRoute><Assets /></AdminRoute>} />
            <Route path="/recharge-plan" element={<AdminRoute><RechargePlan /></AdminRoute>} />
            <Route path="/daily-acts-plan" element={<DailyActsPlan />} />
            <Route path="/daily-plan-request-report" element={<DailyPlanRequestReport />} />
            <Route path="/portin-inventory" element={<AdminRoute><PortinInventory /></AdminRoute>} />


            {/* <Route path="/plan" element={<ManualAddFund />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
