import React, { useEffect, useState } from 'react'
import dashboardActions from '../../redux/dashboard/actions';
import { useDispatch, useSelector } from "react-redux";

const { getActivationCount } = dashboardActions;


const ActivationCount = () => {
    const dispatch = useDispatch();
    const { activationCountData,activationCountLoading } = useSelector((state) => state.dashboard);
    useEffect(() => {
        dispatch(getActivationCount(1))
    }, [])

    const activationData = activationCountData && activationCountData.length > 0 ? activationCountData.map((item, ind) => ({
        ...item,
        ActivationData: JSON.parse(item.ActivationData)
    })) : [];

    return (
        <div>
            <div className="iq-card-header">
                <div className="iq-header-title">
                    <h4 className="card-title font-weight-bold" style={{ fontSize: "18px" }}>Transaction Count</h4>
                </div>
            </div>
            {activationCountLoading ? <div className="dashboard-activation-loader-activation">
                        <span className="spinner-border spinner-border-lg" role="status"></span>
                    </div> : <div className='iq-card-body  pt-1'>
                    {activationData && activationData.length > 0 && activationData.map((data, index) => (
                        <div className='activation_count_parent'>
                         
                        <h2 className='font-weight-bold mb-2' style={{ fontSize: "18px",color: "#880303" }}>{data.AggregatorName}</h2>                     
                        <div className='table-responsive'>
                        <table style={{ width: '100%' }}>
                    <tr>
                        
                        <th>Network</th>
                        <th>Today</th>
                        <th>MTD</th>
                        <th>Last Month</th>
                    </tr>
                    
                        <tr>                            
                            <td>{data.ActivationData && data.ActivationData.length > 0 && data.ActivationData.map((item, ind) => (
                                <div>
                                    {item.NetworkName}
                                </div>
                            ))}</td>
                            <td>{data.ActivationData && data.ActivationData.length > 0 && data.ActivationData.map((item, ind) => (
                                <div>
                                    {item.TodayCount}
                                </div>
                            ))}</td>
                            <td>{data.ActivationData && data.ActivationData.length > 0 && data.ActivationData.map((item, ind) => (
                                <div>
                                    {item.MonthTotal}
                                </div>
                            ))}</td>
                            <td>{data.ActivationData && data.ActivationData.length > 0 && data.ActivationData.map((item, ind) => (
                                <div>
                                    {item.LastMonthTotal}
                                </div>
                            ))}</td>
                        </tr>
                    
                </table>               
                     </div>
                        </div>
                       
                    ))
                    }
                
               
                
            </div>}

        </div>
    )
}

export default ActivationCount
