import { combineReducers } from "redux";
import user from './user/reducer'
import dealer from './dealer/reducer';
import country from './country/reducer'
import auth from './auth/reducer';
import manualAddFund from './manualAddFund/reducer';
import addToFund from './addToFund/reducer';
import plan from './plan/reducer'
import aggregator from './aggregator/reducer'
import addonService from './addonService/reducer';
import network from './network/reducer';
import activationRequest from './activationRequest/reducer';
import profile from './profile/reducer'
import auditReport from './auditReport/reducer'
import planWiseMapping from './planWiseMapping/reducer'
import asset from './asset/reducer';
import addonWiseMapping from './addonWiseMapping/reducer';
import tmoDealer from './tMobileDealer/reducer'
import activity from './activity/reducer'
import dashboard from './dashboard/reducer'
import activationReport from './activationReport/reducer'
import assetPerformanceReport from "./assetPerformanceReport/reducer";
import jarUpload from "./jar/reducer";
import manualActivationReport from './manualActivationReport/reducer'
import bulkActivationReport from './bulkActivationReport/reducer'
import simSwapRequest from './simSwapRequest/reducer'
import rechargeRequest from './rechargeRequest/reducer'
import rechargePlan from './rechargePlan/reducer'
import shortCode from './shortCodeData/reducer'
import emailInbox from './emailInbox/reducer'
import iqRequestReport from './iqRequestReport/reducer'
import dailyPlanRequest from './dailyPlanRequest/reducer';
import portinActivation from "./portinActivation/reducer";
import portinInventory from "./PortinInventory/reducer"


const rootReducer = combineReducers({
  user,
  country,
  manualAddFund,
  addToFund,
  plan,
  addonService,
  dealer,
  auth,
  network,
  activationRequest,
  aggregator,
  profile,
  auditReport,
  planWiseMapping,
  asset,
  addonWiseMapping,
  tmoDealer,
  activity,
  dashboard,
  activationReport,
  assetPerformanceReport,
  jarUpload,
  manualActivationReport,
  bulkActivationReport,
  simSwapRequest,
  rechargeRequest,
  rechargePlan,
  shortCode,
  emailInbox,
  iqRequestReport,
  dailyPlanRequest,
  portinActivation,
  portinInventory,
});
export default rootReducer;