import React, { useState, useEffect, useRef } from 'react'
import Table from '../../../components/utils/Table'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import Button from '../../../components/utils/Button'
import { useDispatch, useSelector } from 'react-redux'
import { removeExtraSpaces, validateZipCode, validateSNo, uniqArrayOfObject } from '../../../helpers/commonFunction';
import Calendar2LineIcon from 'remixicon-react/Calendar2LineIcon';
import DatePicker from "react-datepicker";
import InventoryActions from "../../../redux/PortinInventory/actions"
import CheckLineIcon from 'remixicon-react/CheckLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import NetworkActions from '../../../redux/network/actions'

const { validatePortinSerialNumber, getInventory, addPortinInventory, resetInventoryMessage } = InventoryActions;
const { getActiveNetwork } = NetworkActions

const AddPortinInventory = ({ back }) => {
    const dispatch = useDispatch();
    const datePickerRef = useRef(null);
    const [toDate, setToDate] = useState(new Date())
    const [previewData, setPreviewData] = useState([]);
    const [previewSuccessData, setPreviewSuccessData] = useState([]);
    const [previewFailedData, setPreviewFailedData] = useState([]);
    const [previewSuccessCount, setPreviewSuccessCount] = useState(0);
    const [showPreviewType, setShowPreviewType] = useState(2);
    const [apiCall, setApiCall] = useState(false);
    const [networkId, setNetworkId] = useState(0);
    const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm({
        defaultValues: {}
    });
    const { networkResult } = useSelector((state) => state.network);
    const { inventoryList, validatePortinSerialNumberData, validatePortinSerialNumberLoading, addPortinInventoryLoading, addPortinInventoryMessage } = useSelector((state) => state.portinInventory);

    useEffect(() => {
        dispatch(getActiveNetwork());
        dispatch(getInventory());
    }, []);

    useEffect(() => {
        if (addPortinInventoryMessage) {
            back();
            dispatch(resetInventoryMessage());
        }
    }, [addPortinInventoryMessage]);

    useEffect(() => {
        if (previewData.length > 0) {
            let successPreview = previewData.filter((item) => item.Status == 1);
            let failedPreview = previewData.filter((item) => item.Status == 0);
            setPreviewSuccessData(successPreview);
            setPreviewFailedData(failedPreview);
        }

        if (apiCall && previewData.length > 0) {
            setApiCall(false);
            let validData = previewData.filter((item) => item.Status == 1);
            if (validData.length > 0) {
                let serial_number_array = validData.map((item) => item.SerialNumber);
                dispatch(validatePortinSerialNumber(serial_number_array));
            }
        }
    }, [previewData]);

    useEffect(() => {
        if (validatePortinSerialNumberData.length > 0) {
            setApiCall(false);
            let newDataSource = previewData.map((item) => {
                let count = validatePortinSerialNumberData.filter((item1) => item1.SerialNumber == item.SerialNumber);
                if (count.length > 0) {
                    if (count[0].Status === 0) {
                        item.Valid = <CloseLineIcon color='#e74c3c' />
                        item.Status = 0;
                    }
                    item.Reason = count[0].ExistStatus;
                }
                return item;
            });
            setPreviewData(newDataSource);

            let successCount = validatePortinSerialNumberData.filter((item) => item.Status === 1);
            setPreviewSuccessCount(successCount.length);
        }
    }, [validatePortinSerialNumberData]);

    const previewColumns = [
        { displayName: 'SERIAL NUMBER', key: 'SerialNumber' },
        { displayName: 'REASON', key: 'Reason' },
        { displayName: 'VALID', key: 'Valid' },
    ];

    const openDatePicker = () => {
        datePickerRef.current.setOpen(true);
    };

    const handleTextareaChange = (val) => {
        setPreviewSuccessCount(0);
        setPreviewData([]);
        clearErrors(['inputData']);

        const dataArr = val.trim() !== '' ? val.trim().split('\n') : [];

        const previewdata = dataArr.map((item) => {
            let serialNumber = removeExtraSpaces(item);
            let error = false;
            let reason = "";

            if (!validateSNo(serialNumber, networkId, 25)) {
                reason = "Invalid Serial Number";
                error = true;
            }

            if (!error) {
                setPreviewSuccessCount((pre) => pre + 1);
            }

            return {
                SerialNumber: serialNumber,
                Reason: reason,
                Valid: error ? <CloseLineIcon color='#e74c3c' /> : <CheckLineIcon color='#2ecc71' />,
                Status: error ? 0 : 1
            };
        });

        const uniqueSerialNumberArr = uniqArrayOfObject(previewdata, 'SerialNumber');

        if (uniqueSerialNumberArr.length !== previewdata.length) {
            setError("inputData", { type: "custom", message: "Data contain duplicate sim number" });
            return false;
        }

        if (previewdata.length > 500) {
            setError("inputData", { type: "custom", message: "Data should not be more than 500 rows" });
            return false;
        }

        if (previewdata.length > 0) {
            setPreviewData(previewdata);
            setApiCall(true);
        }
    };

    const handlePreviewTableData = (id) => {
        setShowPreviewType(id)
    }

    const handleNetwork = (e) => {
        e.preventDefault();
        setNetworkId(e.target.value);
    }

    const handleAddPortinInventory = (data) => {
        const requestData = previewData.map((item) => ({ SerialNumber: item.SerialNumber }));
        data.purchaseDate = moment(toDate).format('YYYY-MM-DD');
        data.purchaseNumber = inventoryList.PurchaseNumber;
        data.invoiceNumber = inventoryList.InvoiceNumber;
        data.requestData = requestData;
        dispatch(addPortinInventory(data));
    };


    return (
        <>
            <div className="row align-items-center mb-4">
                <div className='col-md-12 mb-2'>
                    <form onSubmit={handleSubmit(handleAddPortinInventory)}>
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <label className='ml-2'> Network <span className='text-danger'>*</span></label>
                                <select className="form-control" placeholder="Select Network" name="networkID"  {...register("networkID", {
                                    required: 'Network is Required',
                                })} onChange={(e) => { handleNetwork(e) }} >
                                    <option value="" key="0">Select</option>
                                    {networkResult.filter(network => network.ID == 9).map(network => (
                                        <option name="network" value={network.ID} key={network.ID}>{network.NetworkName}</option>
                                    ))}
                                </select>
                                {errors && errors.networkID && (<div className="invalid-feedback">{errors.networkID.message}</div>)}
                            </div>
                            <div className="col-lg-3 col-md-4 mb-2 d-flex flex-column">
                                <label>Purchase Date</label>
                                <Calendar2LineIcon onClick={openDatePicker} size={'1.5rem'} style={{ cursor: 'pointer', position: 'absolute', 'top': '36px', right: '22px', zIndex: 1 }} />
                                <DatePicker
                                    ref={datePickerRef}
                                    selected={toDate}
                                    className="form-control"
                                    onChange={(date) => setToDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    name="toDate"
                                    showMonthDropdown
                                    showYearDropdown
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    maxDate={new Date()}
                                    value={toDate}
                                />
                            </div>
                            <div className="col-lg-3">
                                <label>Purchase Number</label>
                                <input type="text" placeholder="Purchase Number" disabled={true} className="form-control" name="purchaseNumber" value={inventoryList.PurchaseNumber} />
                            </div>
                            <div className="col-lg-3">
                                <label>Invoice Number</label>
                                <input type="text" placeholder="Purchase Number" disabled={true} className="form-control" name="invoiceNumber" value={inventoryList.InvoiceNumber} />
                            </div>
                            {
                                networkId != 0 &&
                                <div className="col-lg-6">
                                    <label htmlFor="data">Data<span className='text-danger'>*</span></label>
                                    <textarea className="form-control" id="data" rows="5" style={{ resize: 'none' }} onChange={(e) => handleTextareaChange(e.target.value)}>
                                    </textarea>
                                    {errors && errors.inputData && (<div className="invalid-feedback">{errors.inputData.message}</div>)}
                                </div>
                            }
                            {
                                networkId != 0 &&
                                <div className="col-lg-6">
                                    <label htmlFor="data" className="example-label">Example</label>
                                    <div className="data-row">
                                        <span className="data-heading">Serial Number</span>
                                    </div>
                                    <div className="data-row">
                                        <span>12345678998765432177</span>
                                    </div>
                                    <div className="data-row">
                                        <span>12345678998765432178</span>
                                    </div>
                                    <div className="data-row">
                                        <span className="data-dot">.....</span>
                                    </div>
                                </div>
                            }

                        </div>

                        {
                            previewData.length > 0 &&
                            <>
                                <hr />
                                <div className='row p-0 m-0' style={{ cursor: 'pointer' }}>
                                    <div className="col-md-4 d-flex justify-content-around align-items-center py-3 preview-box" onClick={() => { handlePreviewTableData(2) }}>
                                        <h4>Total Submited : {previewData.length}</h4>
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-around align-items-center preview-box" onClick={() => { handlePreviewTableData(1) }}>
                                        <h4 className='text-success'>Valid : {previewSuccessCount}</h4>
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-around align-items-center preview-box" onClick={() => { handlePreviewTableData(0) }}>
                                        <h4 className='text-danger'>Invalid : {previewData.length - previewSuccessCount}</h4>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3 p-0">
                                    <Table columns={previewColumns}
                                        loading={validatePortinSerialNumberLoading}
                                        data={showPreviewType == 1 ? previewSuccessData : showPreviewType == 0 ? previewFailedData : previewData}
                                        pagination={false} />
                                </div>
                            </>
                        }
                        <div className='text-right row mt-2'>
                            <div className="col-md-12">
                                <button type="button" className="btn btn-secondary mr-2" onClick={() => back()}>Close</button>
                                {previewData.length > 0 && (
                                    <Button loading={addPortinInventoryLoading} title="Submit" disable={previewData.length - previewSuccessCount !== 0} style="minWidth:80px" buttonClass="btn btn-primary" buttonType="submit" />
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddPortinInventory