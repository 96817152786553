const actions = {
    GET_NETWORK_BY_DEALER_PORTIN_MAPPING: 'GET_NETWORK_BY_DEALER_PORTIN_MAPPING',
    GET_NETWORK_BY_DEALER_PORTIN_MAPPING_SUCCESS: 'GET_NETWORK_BY_DEALER_PORTIN_MAPPING_SUCCESS',
    GET_NETWORK_BY_DEALER_PORTIN_MAPPING_FAILED: 'GET_NETWORK_BY_DEALER_PORTIN_MAPPING_FAILED',

    CREATE_PORTIN_ACTIVATION: 'CREATE_PORTIN_ACTIVATION',
    CREATE_PORTIN_ACTIVATION_SUCCESS: 'CREATE_PORTIN_ACTIVATION_SUCCESS',
    CREATE_PORTIN_ACTIVATION_FAILED: 'CREATE_PORTIN_ACTIVATION_FAILED',

    GET_BULK_PORTIN_ACTIVATION: 'GET_BULK_PORTIN_ACTIVATION',
    GET_BULK_PORTIN_ACTIVATION_SUCCESS: 'GET_BULK_PORTIN_ACTIVATION_SUCCESS',
    GET_BULK_PORTIN_ACTIVATION_FAILED: 'GET_BULK_PORTIN_ACTIVATION_FAILED',

    RESET_MESSAGES: 'RESET_MESSAGES',
    RESET_DATA: 'RESET_DATA',

    resetData: () => ({
        type: actions.RESET_DATA,
    }),

    resetMessage: () => ({
        type: actions.RESET_MESSAGES,
    }),

    getNetworkByDealerPortinMapping: () => ({
        type: actions.GET_NETWORK_BY_DEALER_PORTIN_MAPPING,
    }),
    getNetworkByDealerPortinMappingSuccess: (result) => ({
        type: actions.GET_NETWORK_BY_DEALER_PORTIN_MAPPING_SUCCESS,
        result
    }),
    getNetworkByDealerPortinMappingFailed: (error) => ({
        type: actions.GET_NETWORK_BY_DEALER_PORTIN_MAPPING_FAILED,
        error
    }),

    createPortinActivation: (data) => ({
        type: actions.CREATE_PORTIN_ACTIVATION,
        data
    }),
    createPortinActivationSuccess: (result,message) => ({
        type: actions.CREATE_PORTIN_ACTIVATION_SUCCESS,
        result,message
    }),
    createPortinActivationFailed: (error) => ({
        type: actions.CREATE_PORTIN_ACTIVATION_FAILED,
        error
    }),

    getBulkPortinActivation: () => ({
        type: actions.GET_BULK_PORTIN_ACTIVATION,
    }),
    getBulkPortinActivationSuccess: (result) => ({
        type: actions.GET_BULK_PORTIN_ACTIVATION_SUCCESS,
        result
    }),
    getBulkPortinActivationFailed: (error) => ({
        type: actions.GET_BULK_PORTIN_ACTIVATION_FAILED,
        error
    }),

};
export default actions;
