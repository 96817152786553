import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';


function* getPlanWiseMappingResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getAllNetworkPlans', data);
        if (response.Status) {
            yield put(actions.getPlanWiseMappingSuccess(response.Data));
        }
        else {
            yield put(actions.getPlanWiseMappingFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanWiseMappingFailed(error.Message));
    }
}

function* getAllPlanPriceMappingDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getPlanPriceMapping', data);
        if (response.Status) {
            yield put(actions.getAllPlanPriceMappingDataSuccess(response.Data));
        } else {
            yield put(actions.getAllPlanPriceMappingDataFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getAllPlanPriceMappingDataFailed(error.Message));
    }
}

function* getRechargeMappingDataResponse() {
    try {
        const response = yield call(getList, '/rechargeMapping');
        if (response.Status) {
            yield put(actions.getRechargeMappingSuccess(response.Data));
        } else {
            yield put(actions.getRechargeMappingFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getRechargeMappingFailed(error.Message));
    }
}

function* updatePlanPriceMappingDataResponse({ data, searchData }) {
    try {
        const response = yield call(addData, '/planPriceMapping', data);
        if (response.Status) {
            const responseList = yield call(addData, '/getPlanPriceMapping', searchData);
            if (responseList.Status) {
                yield put(actions.updatePlanPriceMappingDataSuccess(response.Data[0].Message, responseList.Data));
            }
            else {
                yield put(actions.getPlanWiseMappingFailed(response.Data[0].Message));
            }

        } else {
            yield put(actions.getPlanWiseMappingFailed(response.Data[0].Message));
        }
    } catch (error) {
        yield put(actions.updatePlanPriceMappingDataFailed(error.Message));
    }
}

function* updateRechargeMappingDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/rechargeMapping', data);
        if (response.Status) {
            yield put(actions.updateRechargeMappingDataSuccess(response.Message));
            yield put(actions.getRechargeMapping());
        } else {
            yield put(actions.updateRechargeMappingDataFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updateRechargeMappingDataFailed(error.Message));
    }
}

function* checkRechargeMappingExistsDataResponse() {
    try {
        const response = yield call(getList, '/checkRechargeMappingExist');
        if (response.Status) {
            yield put(actions.checkRechargeMappingExistsSuccess(response.Message));
        } else {
            yield put(actions.checkRechargeMappingExistsFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.checkRechargeMappingExistsFailed(error.Message));
    }
}

function* updatePlanChildPriceMappingDataResponse({ data, searchData }) {
    try {
        const response = yield call(addData, '/updateChildPriceMapping', data);
        if (response.Status) {
            const responseList = yield call(addData, '/getPlanPriceMapping', searchData);
            if (responseList.Status) {
                yield put(actions.updatePlanChildPriceMappingDataSuccess(response.Data[0].Message, responseList.Data));
            }
            else {
                yield put(actions.getPlanWiseMappingFailed(response.Data[0].Message));
            }

        } else {
            yield put(actions.getPlanWiseMappingFailed(response.Data[0].Message));
        }
    } catch (error) {
        yield put(actions.updatePlanChildPriceMappingDataFailed(error.Message));
    }
}

function* getDailyPlanMappingDataResponse() {
    try {
        const response = yield call(getList, '/dailyPlanMapping');
        if (response.Status) {
            yield put(actions.getDailyPlanMappingSuccess(response.Data));
        } else {
            yield put(actions.getDailyPlanMappingFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getDailyPlanMappingFailed(error.message));
    }
}

function* updateDailyPlanMappingDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/dailyPlanMapping', data);
        if (response.Status) {
            yield put(actions.updateDailyPlanMappingSuccess(response.Message));
            yield put(actions.getDailyPlanMapping());
        } else {
            yield put(actions.updateDailyPlanMappingFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updateDailyPlanMappingFailed(error.Message));
    }
}

function* getPortinMappingDataResponse() {
    try {
        const response = yield call(getList, '/portinMapping');
        if (response.Status) {
            yield put(actions.getPortinMappingSuccess(response.Data));
        } else {
            yield put(actions.getPortinMappingFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getPortinMappingFailed(error.Message));
    }
}

function* updatePortinMappingDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/portinMapping', data);
        if (response.Status) {
            yield put(actions.updatePortinMappingDataSuccess(response.Message));
            yield put(actions.getPortinMapping());
        } else {
            yield put(actions.updatePortinMappingDataFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updatePortinMappingDataFailed(error.Message));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.UPDATE_PORTIN_MAPPING_DATA, updatePortinMappingDataResponse)]);
    yield all([takeEvery(actions.GET_PORTIN_MAPPING_DATA, getPortinMappingDataResponse)]);
    yield all([takeEvery(actions.UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA, updatePlanChildPriceMappingDataResponse)]);
    yield all([takeEvery(actions.GET_PLAN_WISE_MAPPING, getPlanWiseMappingResponse)]);
    yield all([takeEvery(actions.GET_ALL_PLAN_PRICE_MAPPING_DATA, getAllPlanPriceMappingDataResponse)]);
    yield all([takeEvery(actions.UPDATE_PLAN_PRICE_MAPPING_DATA, updatePlanPriceMappingDataResponse)]);
    yield all([takeEvery(actions.GET_RECHARGE_MAPPING_DATA, getRechargeMappingDataResponse)]);
    yield all([takeEvery(actions.UPDATE_RECHARGE_MAPPING_DATA, updateRechargeMappingDataResponse)]);
    yield all([takeEvery(actions.CHECK_RECHARGE_MAPPING_EXISTS, checkRechargeMappingExistsDataResponse)]);
    yield all([takeEvery(actions.GET_DAILY_PLAN_MAPPING_DATA, getDailyPlanMappingDataResponse)]);
    yield all([takeEvery(actions.UPDATE_DAILY_PLAN_MAPPING_DATA, updateDailyPlanMappingDataResponse)]);
}