import { all } from 'redux-saga/effects';
import userSagas from './user/saga'
import dealerSagas from './dealer/saga'
import authSagas from './auth/saga';
import countrySagas from './country/saga';
import manualAddFundSagas from './manualAddFund/saga';
import addToFundSagas from './addToFund/saga';
import planSagas from './plan/saga';
import aggregatorSagas from './aggregator/saga'
import addonServiceSagas from './addonService/saga';
import networkSagas from './network/saga';
import profileSagas from './profile/saga';
import activationRequestSagas from './activationRequest/saga';
import auditReportSagas from './auditReport/saga'
import planWiseMappingSagas from './planWiseMapping/saga'
import assetSagas from './asset/saga'
import addonWiseMappingSagas from './addonWiseMapping/saga';
import tmoDealerSagas from './tMobileDealer/saga'
import activitySagas from './activity/saga';
import dashboardSagas from './dashboard/saga';
import activationReportSagas from './activationReport/saga'
import assetPerformanceSagas from './assetPerformanceReport/saga'
import jarUploadSagas from './jar/saga'
import manualActivationReportSagas from './manualActivationReport/saga'
import bulkActivationReportSagas from './bulkActivationReport/saga'
import simSwapRequestSagas from './simSwapRequest/saga'
import rechargeRequestSagas from './rechargeRequest/saga';
import rechargePlanSagas from './rechargePlan/saga'
import shortCodeSagas from './shortCodeData/saga'
import emailInboxSagas from './emailInbox/saga'
import iqRequestReportSagas from './iqRequestReport/saga'
import dailyPlanRequestSagas from './dailyPlanRequest/saga'
import portinActivationSagas from './portinActivation/saga'
import portinInventorySagas from './PortinInventory/saga'


export default function* rootSaga() {
        yield all([
                userSagas(),
                dealerSagas(),
                countrySagas(),
                dealerSagas(),
                authSagas(),
                manualAddFundSagas(),
                addToFundSagas(),
                planSagas(),
                addonServiceSagas(),
                networkSagas(),
                activationRequestSagas(),
                aggregatorSagas(),
                profileSagas(),
                auditReportSagas(),
                planWiseMappingSagas(),
                assetSagas(),
                addonWiseMappingSagas(),
                tmoDealerSagas(),
                activitySagas(),
                dashboardSagas(),
                activationReportSagas(),
                assetPerformanceSagas(),
                jarUploadSagas(),
                manualActivationReportSagas(),
                bulkActivationReportSagas(),
                simSwapRequestSagas(),
                rechargeRequestSagas(),
                rechargePlanSagas(),
                shortCodeSagas(),
                emailInboxSagas(),
                iqRequestReportSagas(),
                dailyPlanRequestSagas(),
                portinActivationSagas(),
                portinInventorySagas(),
        ])
}