import React, { useState, useEffect } from 'react'
import CardLayout from '../../../components/utils/CardLayout'
import SearchBar from '../../../components/utils/SearchBar'
import SideButtons from '../../../components/utils/SideButtons'
import Table from '../../../components/utils/Table'
import { useDispatch, useSelector } from 'react-redux'
import { convertDateToRequiredFormatTZ } from '../../../helpers/commonFunction';
import InventoryActions from "../../../redux/PortinInventory/actions"
import { Link } from 'react-router-dom';
import EyeFill from 'remixicon-react/EyeFillIcon';
import AddPortinInventory from './AddPortinInventory'
import InventoryViewDetails from './InventoryViewDetails'
import Alert from '../../../components/utils/Alert'

const { getInventoryList, getInventoryViewDetails } = InventoryActions;

const PortinInventory = () => {
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState("");
    const [activeForm, setActiveForm] = useState("list");
    const [selectedInventoryId, setSelectedInventoryId] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const { inventoryData, inventoryDataLoading, addPortinInventoryMessage } = useSelector((state) => state.portinInventory);

    useEffect(() => {

        if (addPortinInventoryMessage !== null && addPortinInventoryMessage !== undefined) {
            displayAlert(addPortinInventoryMessage, 'alert-success');
        }

    }, [addPortinInventoryMessage]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
    }

    useEffect(() => {
        dispatch(getInventoryList());
    }, []);

    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'PURCHASE NUMBER', key: 'purchaseNumber' },
        { displayName: 'INVOICE NUMBER', key: 'invoiceNumber' },
        { displayName: 'PURCHASE DATE', key: 'purchaseDate' },
        { displayName: 'PURCHASED BY(USER)', key: 'purchasedBy' },
        { displayName: 'ACTION', key: 'action' },
    ];

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const handleAddInventory = () => {
        setActiveForm("add");
    };

    const handleShowInventoryDetails = (ID) => {
        setSelectedInventoryId(ID);
        setActiveForm("view");
        dispatch(getInventoryViewDetails(ID));
    };

    const filteredItems = Array.isArray(inventoryData) ? inventoryData.filter(item =>
        Object.values(item).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase())
        )
    ) : [];

    const dataSource = filteredItems.map((item, key) => ({
        key: key + 1,
        purchaseNumber: item.PurchaseNumber,
        invoiceNumber: item.InvoiceNumber,
        purchasedBy: item.PurchaseName,
        purchaseDate: convertDateToRequiredFormatTZ(item.PurchaseDtTm),
        action: (
            <Link to="#" onClick={() => handleShowInventoryDetails(item.ID)}>
                <span data-toggle="tooltip" data-placement="top" title="View">
                    <EyeFill size="1.2em" style={{ color: "#294c28eb" }} />
                </span>
            </Link>
        )
    }));

    const renderForm = () => {
        switch (activeForm) {
            case "add":
                return <AddPortinInventory back={() => setActiveForm("list")} />;
            case "view":
                return <InventoryViewDetails back={() => setActiveForm("list")} id={selectedInventoryId} />;
            default:
                return (
                    <>
                        <div className="row">
                            <div className='col-lg-6 col-md-6'>
                                <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                            </div>
                            <div className='col-lg-6 col-md-6 text-right'>
                                <SideButtons title="Add Inventory" buttonStyle="primary" onClick={handleAddInventory} />
                            </div>
                        </div>
                        <Table columns={columns} loading={inventoryDataLoading} data={dataSource} pagination={true} />
                    </>
                );
        }
    };
    return (
        <CardLayout
            title={
                activeForm === "add" ? "Add Portin Inventory" :
                    activeForm === "view" ? "Inventory Details" :
                        "Portin Inventory"
            }
        >
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            {renderForm()}
        </CardLayout>
    );
};

export default PortinInventory;
