import actions from "./actions";

const initState = {
    inventoryList: [],
    inventoryLoading: false,
    inventoryError: null,

    validatePortinSerialNumberLoading: false,
    validatePortinSerialNumberError: null,
    validatePortinSerialNumberData: [],

    inventoryData: [],
    inventoryDataLoading: false,
    inventoryDataError: null,

    inventoryViewDetails: [],
    inventoryViewDetailsLoading: false,
    inventoryViewError: null,

    addPortinInventoryLoading: false,
    addPortinInventoryError: null,
    addPortinInventoryMessage: null,

};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.RESET_INVENTORY_MESSAGE:
            return {
                ...state,
                addPortinInventoryError: null,
                addPortinInventoryMessage: null,
            };

        case actions.ADD_PORTIN_INVENTORY:
            return {
                ...state,
                addPortinInventoryLoading: true,
                addPortinInventoryError: null,
                addPortinInventoryMessage: null,
            };
        case actions.ADD_PORTIN_INVENTORY_SUCCESS:
            return {
                ...state,
                addPortinInventoryLoading: false,
                addPortinInventoryError: null,
                addPortinInventoryMessage: action.payload.message,
            };
        case actions.ADD_PORTIN_INVENTORY_FAILED:
            return {
                ...state,
                addPortinInventoryLoading: false,
                addPortinInventoryError: action.payload.error,
                addPortinInventoryMessage: null,
            };


        case actions.GET_INVENTORY_DETAILS:
            return {
                ...state,
                inventoryLoading: true,
                inventoryError: null,
                inventoryList: []
            };
        case actions.GET_INVENTORY_DETAILS_SUCCESS:
            return {
                ...state,
                inventoryLoading: false,
                inventoryList: action.result,
                inventoryError: null,
            };
        case actions.GET_INVENTORY_DETAILS_FAILED:
            return {
                ...state,
                inventoryLoading: false,
                inventoryError: action.error,
            };

        case actions.VALIDATE_PORTIN_SERIAL_NUMBER:
            return {
                ...state,
                validatePortinSerialNumberLoading: true,
                validatePortinSerialNumberError: null,
                validatePortinSerialNumberData: [],
            };
        case actions.VALIDATE_PORTIN_SERIAL_NUMBER_SUCCESS:
            return {
                ...state,
                validatePortinSerialNumberLoading: false,
                validatePortinSerialNumberError: null,
                validatePortinSerialNumberData: action.payload.data,
            };
        case actions.VALIDATE_PORTIN_SERIAL_NUMBER_FAILED:
            return {
                ...state,
                validatePortinSerialNumberLoading: false,
                validatePortinSerialNumberError: null,
                validatePortinSerialNumberData: [],
            };

        case actions.GET_INVENTORY_LIST:
            return {
                ...state,
                inventoryDataLoading: true,
                inventoryDataError: null,
                inventoryData: []
            };
        case actions.GET_INVENTORY_LIST_SUCCESS:
            return {
                ...state,
                inventoryDataLoading: false,
                inventoryData: action.result,
                inventoryDataError: null,
            };
        case actions.GET_INVENTORY_LIST_FAILED:
            return {
                ...state,
                inventoryDataLoading: false,
                inventoryDataError: action.error,
            };

        case actions.GET_INVENTORY_VIEW_DETAILS:
            return {
                ...state,
                inventoryViewDetailsLoading: true,
                inventoryViewDetails: [],
                inventoryViewError: false,
            };
        case actions.GET_INVENTORY_VIEW_DETAILS_SUCCESS:
            return {
                ...state,
                inventoryViewDetailsLoading: false,
                inventoryViewDetails: action.result,
                inventoryViewError: false,
            };
        case actions.GET_INVENTORY_VIEW_DETAILS_FAILED:
            return {
                ...state,
                inventoryViewDetails: [],
                inventoryViewDetailsLoading: false,
                inventoryViewError: action.error,
            };

        default:
            return state;
    }
}
