const actions = {
    GET_INVENTORY_DETAILS: 'GET_INVENTORY_DETAILS',
    GET_INVENTORY_DETAILS_SUCCESS: 'GET_INVENTORY_DETAILS_SUCCESS',
    GET_INVENTORY_DETAILS_FAILED: 'GET_INVENTORY_DETAILS_FAILED',

    VALIDATE_PORTIN_SERIAL_NUMBER: "VALIDATE_PORTIN_SERIAL_NUMBER",
    VALIDATE_PORTIN_SERIAL_NUMBER_SUCCESS: "VALIDATE_PORTIN_SERIAL_NUMBER_SUCCESS",
    VALIDATE_PORTIN_SERIAL_NUMBER_FAILED: "VALIDATE_PORTIN_SERIAL_NUMBER_FAILED",

    GET_INVENTORY_LIST: 'GET_INVENTORY_LIST',
    GET_INVENTORY_LIST_SUCCESS: 'GET_INVENTORY_LIST_SUCCESS',
    GET_INVENTORY_LIST_FAILED: 'GET_INVENTORY_LIST_FAILED',

    GET_INVENTORY_VIEW_DETAILS: 'GET_INVENTORY_VIEW_DETAILS',
    GET_INVENTORY_VIEW_DETAILS_SUCCESS: 'GET_INVENTORY_VIEW_DETAILS_SUCCESS',
    GET_INVENTORY_VIEW_DETAILS_FAILED: 'GET_INVENTORY_VIEW_DETAILS_FAILED',

    ADD_PORTIN_INVENTORY: 'ADD_PORTIN_INVENTORY',
    ADD_PORTIN_INVENTORY_SUCCESS: 'ADD_PORTIN_INVENTORY_SUCCESS',
    ADD_PORTIN_INVENTORY_FAILED: 'ADD_PORTIN_INVENTORY_FAILED',
    
    RESET_INVENTORY_MESSAGE: 'RESET_INVENTORY_MESSAGE',

    resetInventoryMessage: () => ({
        type: actions.RESET_INVENTORY_MESSAGE,
    }),

    addPortinInventory: (data) => ({
        type: actions.ADD_PORTIN_INVENTORY,
        payload: { data }
    }),
    addPortinInventorySuccess: (message) => ({
        type: actions.ADD_PORTIN_INVENTORY_SUCCESS,
        payload: { message }
    }),
    addPortinInventoryFailed: (error) => ({
        type: actions.ADD_PORTIN_INVENTORY_FAILED,
        payload: { error }
    }),

    getInventory: () => ({
        type: actions.GET_INVENTORY_DETAILS,
    }),
    getInventorySuccess: (result) => ({
        type: actions.GET_INVENTORY_DETAILS_SUCCESS,
        result
    }),
    getInventoryFailed: (error) => ({
        type: actions.GET_INVENTORY_DETAILS_FAILED,
        error
    }),

    validatePortinSerialNumber: (data) => ({
        type: actions.VALIDATE_PORTIN_SERIAL_NUMBER,
        payload: { data }
    }),
    validatePortinSerialNumberSuccess: (data) => ({
        type: actions.VALIDATE_PORTIN_SERIAL_NUMBER_SUCCESS,
        payload: { data }
    }),
    validatePortinSerialNumberFailed: (error) => ({
        type: actions.VALIDATE_PORTIN_SERIAL_NUMBER_FAILED,
        payload: { error }
    }),

    getInventoryList: () => ({
        type: actions.GET_INVENTORY_LIST,
    }),
    getInventoryListSuccess: (result) => ({
        type: actions.GET_INVENTORY_LIST_SUCCESS,
        result
    }),
    getInventoryListFailed: (error) => ({
        type: actions.GET_INVENTORY_LIST_FAILED,
        error
    }),

    getInventoryViewDetails: (portinId) => ({
        type: actions.GET_INVENTORY_VIEW_DETAILS,
        portinId
    }),
    getInventoryViewDetailsSuccess: (result) => ({
        type: actions.GET_INVENTORY_VIEW_DETAILS_SUCCESS,
        result
    }),
    getInventoryViewDetailsFailed: (error) => ({
        type: actions.GET_INVENTORY_VIEW_DETAILS_FAILED,
        error
    }),

};
export default actions;
