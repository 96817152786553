import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';

function* getInventoryListResponse() {
    try {
        const response = yield call(getList, '/makeInvoice');
        if (response.response.Status) {
            yield put(actions.getInventorySuccess(response.response.Data));
        } else {
            yield put(actions.getInventoryFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getInventoryFailed(error));
    }
}

function* validatePortinSerialNumberResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/validatePortinSerialNumber', data);
        if (response.Status) {
            yield put(actions.validatePortinSerialNumberSuccess(response.Data));
        } else {
            yield put(actions.validatePortinSerialNumberFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.validatePortinSerialNumberFailed(error));
    }
}

function* getInventoryDataResponse() {
    try {
        const response = yield call(getList, '/inventoryDetails');
        if (response.response.Status) {
            yield put(actions.getInventoryListSuccess(response.response.Data));
        } else {
            yield put(actions.getInventoryListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getInventoryListFailed(error));
    }
}

function* getInventoryViewDetailsResponse({ portinId }) {
    try {
        const response = yield call(getList, '/inventoryViewDetails/' + portinId);
        if (response.response.Status) {
            yield put(actions.getInventoryViewDetailsSuccess(response.response.Data));
        } else {
            yield put(actions.getInventoryViewDetailsFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.getInventoryViewDetailsFailed(error));
    }
}

function* addPortinInventoryResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addPortinInventory', data);
        if (response.Status) {
            yield put(actions.addPortinInventorySuccess(response.Message));
            yield put(actions.getInventoryList())
        } else {
            yield put(actions.addPortinInventoryFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.addPortinInventoryFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_INVENTORY_DETAILS, getInventoryListResponse)]);
    yield all([takeEvery(actions.VALIDATE_PORTIN_SERIAL_NUMBER, validatePortinSerialNumberResponse)]);
    yield all([takeEvery(actions.GET_INVENTORY_LIST, getInventoryDataResponse)]);
    yield all([takeEvery(actions.GET_INVENTORY_VIEW_DETAILS, getInventoryViewDetailsResponse)]);
    yield all([takeEvery(actions.ADD_PORTIN_INVENTORY, addPortinInventoryResponse)]);

}