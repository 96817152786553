import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './Sidebar.css';
import AuthActions from '../../redux/auth/actions'
import dealerAction from '../../redux/dealer/actions'
import { useDispatch, useSelector } from 'react-redux';
import { isAdmin, isDealer, isAdminAgent, allowDealerCreation, isAdminOrAgent } from '../../helpers/commonFunction';
import Logo from '../../../src/assets/images/Long.png'
const { getUserDataByToken } = AuthActions;
function Sidebar() {
    const dispatch = useDispatch();
    const { getDealerLevel, } = dealerAction;
    const tokenData = useSelector((state) => state.auth.tokenData);
    // const dealerLevelResult = useSelector((state) => state.dealer.dealerLevelResult);
    useEffect(() => {
        dispatch(getUserDataByToken());
        dispatch(getDealerLevel());
    }, [dispatch]);

    return (
        <div className="iq-sidebar" >
            <div className="iq-sidebar-logo d-flex justify-content-between">
                <a href="/">
                    <img src={Logo} alt="Logo" style={{ height: "85px" }} />
                </a>
            </div>
            <div id="sidebar-scrollbar">
                <nav className="iq-sidebar-menu">
                    <ul id="iq-sidebar-toggle" className="iq-menu">

                        {true && <li className='shouldActive'> <NavLink to="/" className="iq-waves-effect"><i className="ri-home-4-line"></i><span>Dashboard</span></NavLink> </li>}

                        {(isAdmin(tokenData) || isDealer(tokenData)) && <li><a href="#admin" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="ri-admin-line"></i><span>Admin</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                            <ul id="admin" class="pl-3 collapse" data-parent="#iq-sidebar-toggle">
                                {(isAdmin(tokenData)) || (allowDealerCreation(tokenData)) ? <li className='shouldActive'><NavLink to="/dealer" className="iq-waves-effect"><i className="ri-shield-user-line"></i><span>Dealer</span></NavLink></li> : ''}
                                {(isAdmin(tokenData) || isDealer(tokenData)) && <li className='shouldActive'><NavLink to="/user" className="iq-waves-effect"><i className="ri-user-line"></i><span>User</span></NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/t-mobile-dealer" className="iq-waves-effect"><i className="ri-contacts-book-2-line"></i><span>T - Mobile Dealer</span></NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/manual-add-fund" className="iq-waves-effect"><i className="ri-money-dollar-circle-line"></i><span>Manual Add Fund</span></NavLink></li>}
                            </ul>
                        </li>}
                        {(isAdmin(tokenData) || isDealer(tokenData) || isAdminAgent(tokenData)) && <li><a href="#config" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-list-settings-line"></i><span>Config</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                            <ul id="config" class="pl-3 collapse" data-parent="#iq-sidebar-toggle">
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/network"><i className="ri-ie-line"></i>Network</NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/aggregator"><i className="ri-user-2-line"></i>Aggregator</NavLink></li>}
                                {(isAdmin(tokenData) || isDealer(tokenData)) && <li className='shouldActive'><NavLink to="/plan"><i className="ri-building-2-line"></i>Plan</NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/recharge-plan"><i className="ri-building-4-line"></i>Recharge Plan</NavLink></li>}

                                {(isAdmin(tokenData) || isDealer(tokenData)) && <li className='shouldActive'><NavLink to="/addon-service"><i className="ri-building-3-line"></i>Addon Service</NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/plan-price-mapping"><i className="ri-price-tag-2-line"></i>Plan Price Mapping</NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/addon-price-mapping"><i className="ri-china-railway-line"></i>Addon Price Mapping</NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/asset"><i className="ri-checkbox-blank-line"></i>Asset</NavLink></li>}
                                {(isAdmin(tokenData) || isAdminAgent(tokenData)) && <li className='shouldActive'><NavLink to="/upload-jar"><i className="ri-chrome-fill"></i>JAR Download</NavLink></li>}
                                {(allowDealerCreation(tokenData)) && <li className='shouldActive'><NavLink to="/dealer-plan-price-mapping"><i className="ri-price-tag-2-line"></i>Plan Price Mapping</NavLink></li>}
                                {(allowDealerCreation(tokenData)) && <li className='shouldActive'><NavLink to="/dealer-addon-price-mapping"><i className="ri-china-railway-line"></i>Addon Price Mapping</NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/portin-inventory" className="iq-waves-effect"><i className="ri-archive-line"></i><span>Portin Inventory</span></NavLink></li>}


                            </ul>
                        </li>}
                        <li><a href="" className='iq-waves-effect'><i class="ri-building-3-line"></i><span>Activations</span></a></li>
                        <div className='pl-3 fs-14'>{true && <li className='shouldActive'><NavLink to="/activation-request"><i className="ri-font-color"></i>Activation Request</NavLink></li>}
                            {(isAdmin(tokenData) || isDealer(tokenData)) && <li className='shouldActive'><NavLink to="/portin-activation"><i class="ri-sim-card-2-line"></i>Portin Activation</NavLink></li>}
                            {true && <li className='shouldActive'><NavLink to="/recharge-request"><i className="ri-battery-2-charge-line"></i>Recharge Request</NavLink></li>}
                            {<li className='shouldActive'><NavLink to="/sim-swap-request"><i className="ri-sim-card-2-line"></i>Sim Swap Request</NavLink></li>}
                            {<li className='shouldActive'><NavLink to="/daily-acts-plan"><i className="ri-sim-card-2-line"></i>Daily Acts Plan</NavLink></li>}
                            {<li className='shouldActive'><NavLink to="/email-inbox"><i class="ri-mail-line"></i>E-Mail Inbox</NavLink></li>}
                        </div>
                        <li><a href="#report" class="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i class="ri-file-chart-line"></i><span>Reports</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                            <ul id="report" class="pl-3  collapse" data-parent="#iq-sidebar-toggle">
                                {true && <li className='shouldActive'><NavLink to="/activation-report"><i className="ri-file-chart-line"></i>Activation Report</NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/asset-performance-report"><i className="ri-font-color"></i>Asset Performance Report</NavLink></li>}
                                {(isAdmin(tokenData) || isDealer(tokenData)) && <li className='shouldActive'><NavLink to="/audit-report"><i className="ri-folder-chart-line"></i>Audit Report</NavLink></li>}
                                {(isAdmin(tokenData)) && <li className='shouldActive'><NavLink to="/manual-activation-report"><i className="ri-file-info-line"></i>Manual Activation Report</NavLink></li>}
                                {true && <li className='shouldActive'><NavLink to="/bulk-activation-report"><i className="ri-file-chart-line"></i>Bulk Activation Report</NavLink></li>}
                                {<li className='shouldActive'><NavLink to="/daily-plan-request-report"><i className="ri-sim-card-2-line"></i>Daily Plan Request Report</NavLink></li>}

                            </ul>
                        </li>
                    </ul>
                </nav>
                <div className="p-3"></div>
            </div>
        </div>
    )
}

export default Sidebar