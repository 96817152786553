import actions from "./actions";

const initState = {
    portinNetworkList: [],
    portinNetworkLoading: false,
    portinNetworkError: null,

    createPortinActivationLoading: false,
    createPortinActivationMessage: null,
    createPortinActivationError: null,

    bulkPortinActivationList: [],
    bulkPortinActivationLoading: false,
    bulkPortinActivationError: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_NETWORK_BY_DEALER_PORTIN_MAPPING:
            return {
                ...state,
                portinNetworkList: [],
                portinNetworkLoading: true,
                portinNetworkError: null,
            };
        case actions.GET_NETWORK_BY_DEALER_PORTIN_MAPPING_SUCCESS:
            return {
                ...state,
                portinNetworkLoading: false,
                portinNetworkList: action.result,
                portinNetworkError: null,
            };
        case actions.GET_NETWORK_BY_DEALER_PORTIN_MAPPING_FAILED:
            return {
                ...state,
                portinNetworkLoading: false,
                portinNetworkError: action.error,
            };

        case actions.CREATE_PORTIN_ACTIVATION:
            return {
                ...state,
                createPortinActivationLoading: true,
                createPortinActivationMessage: null,
                createPortinActivationError: null,
            };
        case actions.CREATE_PORTIN_ACTIVATION_SUCCESS:
            return {
                ...state,
                createPortinActivationLoading: false,
                bulkPortinActivationList: action.result,
                createPortinActivationMessage: action.message,
                createPortinActivationError: null,
            };
        case actions.CREATE_PORTIN_ACTIVATION_FAILED:
            return {
                ...state,
                createPortinActivationLoading: false,
                createPortinActivationError: action.error,
                createPortinActivationMessage: null,
            };

        case actions.GET_BULK_PORTIN_ACTIVATION:
            return {
                ...state,
                bulkPortinActivationList: [],
                bulkPortinActivationLoading: true,
                bulkPortinActivationError: null,
            };
        case actions.GET_BULK_PORTIN_ACTIVATION_SUCCESS:
            return {
                ...state,
                bulkPortinActivationLoading: false,
                bulkPortinActivationList: action.result,
                bulkPortinActivationError: null,
            };
        case actions.GET_BULK_PORTIN_ACTIVATION_FAILED:
            return {
                ...state,
                bulkPortinActivationLoading: false,
                bulkPortinActivationError: action.error,
            };

        case actions.RESET_MESSAGES:
            return {
                ...state,
                createPortinActivationMessage: null,
                createPortinActivationError: null,
            };
            case actions.RESET_DATA:
                return {
                    ...state,
                    portinActivationList: [],
                };

        default:
            return state;
    }
}
