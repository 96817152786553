const actions = {
    GET_PLAN_WISE_MAPPING: "GET_PLAN_WISE_MAPPING",
    GET_PLAN_WISE_MAPPING_SUCCESS: "GET_PLAN_WISE_MAPPING_SUCCESS",
    GET_PLAN_WISE_MAPPING_FAILED: "GET_PLAN_WISE_MAPPING_FAILED",
    GET_ALL_PLAN_PRICE_MAPPING_DATA: "GET_ALL_PLAN_PRICE_MAPPING_DATA",
    GET_ALL_PLAN_PRICE_MAPPING_DATA_SUCCESS: "GET_ALL_PLAN_PRICE_MAPPING_DATA_SUCCESS",
    GET_ALL_PLAN_PRICE_MAPPING_DATA_FAILED: "GET_ALL_PLAN_PRICE_MAPPING_DATA_FAILED",
    UPDATE_PLAN_PRICE_MAPPING_DATA: "UPDATE_PLAN_PRICE_MAPPING_DATA",
    UPDATE_PLAN_PRICE_MAPPING_DATA_SUCCESS: "UPDATE_PLAN_PRICE_MAPPING_DATA_SUCCESS",
    UPDATE_PLAN_PRICE_MAPPING_DATA_FAILED: "UPDATE_PLAN_PRICE_MAPPING_DATA_FAILED",
    RESET_PLAN_PRICE_MAPPING_MESSAGES: "RESET_PLAN_PRICE_MAPPING_MESSAGES",
    GET_RECHARGE_MAPPING_DATA: "GET_RECHARGE_MAPPING_DATA",
    GET_RECHARGE_MAPPING_DATA_SUCCESS: "GET_RECHARGE_MAPPING_DATA_SUCCESS",
    GET_RECHARGE_MAPPING_DATA_FAILED: "GET_RECHARGE_MAPPING_DATA_FAILED",
    UPDATE_RECHARGE_MAPPING_DATA: "UPDATE_RECHARGE_MAPPING_DATA",
    UPDATE_RECHARGE_MAPPING_DATA_SUCCESS: "UPDATE_RECHARGE_MAPPING_DATA_SUCCESS",
    UPDATE_RECHARGE_MAPPING_DATA_FAILED: "UPDATE_RECHARGE_MAPPING_DATA_FAILED",
    CHECK_RECHARGE_MAPPING_EXISTS: "CHECK_RECHARGE_MAPPING_EXISTS",
    CHECK_RECHARGE_MAPPING_EXISTS_SUCCESS: "CHECK_RECHARGE_MAPPING_EXISTS_SUCCESS",
    CHECK_RECHARGE_MAPPING_EXISTS_FAILED: "CHECK_RECHARGE_MAPPING_EXISTS_FAILED",
    UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA: "UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA",
    UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA_SUCCESS: "UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA_SUCCESS",
    UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA_FAILED: "UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA_FAILED",
    GET_DAILY_PLAN_MAPPING_DATA: "GET_DAILY_PLAN_MAPPING_DATA",
    GET_DAILY_PLAN_MAPPING_DATA_SUCCESS: "GET_DAILY_PLAN_MAPPING_DATA_SUCCESS",
    GET_DAILY_PLAN_MAPPING_DATA_FAILED: "GET_DAILY_PLAN_MAPPING_DATA_FAILED",
    UPDATE_DAILY_PLAN_MAPPING_DATA: "UPDATE_DAILY_PLAN_MAPPING_DATA",
    UPDATE_DAILY_PLAN_MAPPING_DATA_SUCCESS: "UPDATE_DAILY_PLAN_MAPPING_DATA_SUCCESS",
    UPDATE_DAILY_PLAN_MAPPING_DATA_FAILED: "UPDATE_DAILY_PLAN_MAPPING_DATA_FAILED",
    GET_PORTIN_MAPPING_DATA: "GET_PORTIN_MAPPING_DATA",
    GET_PORTIN_MAPPING_DATA_SUCCESS: "GET_PORTIN_MAPPING_DATA_SUCCESS",
    GET_PORTIN_MAPPING_DATA_FAILED: "GET_PORTIN_MAPPING_DATA_FAILED",
    UPDATE_PORTIN_MAPPING_DATA: "UPDATE_PORTIN_MAPPING_DATA",
    UPDATE_PORTIN_MAPPING_DATA_SUCCESS: "UPDATE_PORTIN_MAPPING_DATA_SUCCESS",
    UPDATE_PORTIN_MAPPING_DATA_FAILED: "UPDATE_PORTIN_MAPPING_DATA_FAILED",

    updatePortinMappingData: (data) => ({
        type: actions.UPDATE_PORTIN_MAPPING_DATA,
        payload: { data }
    }),
    updatePortinMappingDataSuccess: (message) => ({
        type: actions.UPDATE_PORTIN_MAPPING_DATA_SUCCESS,
        payload: { message }
    }),
    updatePortinMappingDataFailed: (error) => ({
        type: actions.UPDATE_PORTIN_MAPPING_DATA_FAILED,
        payload: { error }
    }),

    getPortinMapping: () => ({
        type: actions.GET_PORTIN_MAPPING_DATA,
    }),
    getPortinMappingSuccess: (data) => ({
        type: actions.GET_PORTIN_MAPPING_DATA_SUCCESS,
        payload: { data }
    }),
    getPortinMappingFailed: (error) => ({
        type: actions.GET_PORTIN_MAPPING_DATA_FAILED,
        payload: { error }
    }),

    updateDailyPlanMapping: (data) => ({
        type: actions.UPDATE_DAILY_PLAN_MAPPING_DATA,
        payload: { data }
    }),
    updateDailyPlanMappingSuccess: (message) => ({
        type: actions.UPDATE_DAILY_PLAN_MAPPING_DATA_SUCCESS,
        payload: { message }
    }),
    updateDailyPlanMappingFailed: (error) => ({
        type: actions.UPDATE_DAILY_PLAN_MAPPING_DATA_FAILED,
        payload: { error }
    }),

    getDailyPlanMapping: () => ({
        type: actions.GET_DAILY_PLAN_MAPPING_DATA,
    }),
    getDailyPlanMappingSuccess: (data) => ({
        type: actions.GET_DAILY_PLAN_MAPPING_DATA_SUCCESS,
        payload: { data }
    }),
    getDailyPlanMappingFailed: (error) => ({
        type: actions.GET_DAILY_PLAN_MAPPING_DATA_FAILED,
        payload: { error }
    }),

    updatePlanChildPriceMappingData: (data, searchData) => ({
        type: actions.UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA,
        data, searchData
    }),
    updatePlanChildPriceMappingDataSuccess: (message, data) => ({
        type: actions.UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA_SUCCESS,
        message, data
    }),
    updatePlanChildPriceMappingDataFailed: (error) => ({
        type: actions.UPDATE_PLAN_CHILD_PRICE_MAPPING_DATA_FAILED,
        error
    }),

    checkRechargeMappingExists: () => ({
        type: actions.CHECK_RECHARGE_MAPPING_EXISTS,
    }),
    checkRechargeMappingExistsSuccess: (message) => ({
        type: actions.CHECK_RECHARGE_MAPPING_EXISTS_SUCCESS,
        payload: { message }
    }),
    checkRechargeMappingExistsFailed: (error) => ({
        type: actions.CHECK_RECHARGE_MAPPING_EXISTS_FAILED,
        payload: { error }
    }),

    updateRechargeMappingData: (data) => ({
        type: actions.UPDATE_RECHARGE_MAPPING_DATA,
        payload: { data }
    }),
    updateRechargeMappingDataSuccess: (message) => ({
        type: actions.UPDATE_RECHARGE_MAPPING_DATA_SUCCESS,
        payload: { message }
    }),
    updateRechargeMappingDataFailed: (error) => ({
        type: actions.UPDATE_RECHARGE_MAPPING_DATA_FAILED,
        payload: { error }
    }),

    getRechargeMapping: () => ({
        type: actions.GET_RECHARGE_MAPPING_DATA,
    }),
    getRechargeMappingSuccess: (data) => ({
        type: actions.GET_RECHARGE_MAPPING_DATA_SUCCESS,
        payload: { data }
    }),
    getRechargeMappingFailed: (error) => ({
        type: actions.GET_RECHARGE_MAPPING_DATA_FAILED,
        payload: { error }
    }),

    getPlanWiseMapping: (data) => ({
        type: actions.GET_PLAN_WISE_MAPPING,
        payload: { data }
    }),
    getPlanWiseMappingSuccess: (data) => ({
        type: actions.GET_PLAN_WISE_MAPPING_SUCCESS,
        payload: { data }
    }),
    getPlanWiseMappingFailed: (error) => ({
        type: actions.GET_PLAN_WISE_MAPPING_FAILED,
        payload: { error }
    }),
    getAllPlanPriceMappingData: (data) => ({
        type: actions.GET_ALL_PLAN_PRICE_MAPPING_DATA,
        payload: { data }
    }),
    getAllPlanPriceMappingDataSuccess: (data) => ({
        type: actions.GET_ALL_PLAN_PRICE_MAPPING_DATA_SUCCESS,
        payload: { data }
    }),
    getAllPlanPriceMappingDataFailed: (error) => ({
        type: actions.GET_ALL_PLAN_PRICE_MAPPING_DATA_FAILED,
        payload: { error }
    }),
    updatePlanPriceMappingData: (data, searchData) => ({
        type: actions.UPDATE_PLAN_PRICE_MAPPING_DATA,
        data, searchData
    }),
    updatePlanPriceMappingDataSuccess: (message, data) => ({
        type: actions.UPDATE_PLAN_PRICE_MAPPING_DATA_SUCCESS,
        message, data
    }),
    updatePlanPriceMappingDataFailed: (error) => ({
        type: actions.UPDATE_PLAN_PRICE_MAPPING_DATA_FAILED,
        error
    }),
    resetPlanPriceMappingMessages: () => ({
        type: actions.RESET_PLAN_PRICE_MAPPING_MESSAGES,
    })

}
export default actions;