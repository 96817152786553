import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, deleteData, editData } from '../../helpers/fackBackend_Helper';

function* getPortinNetworkResponse() {
    try {
        const response = yield call(getList, '/portinNetwork');
        if (response.response.Status) {
            yield put(actions.getNetworkByDealerPortinMappingSuccess(response.response.Data));
        } else {
            yield put(actions.getNetworkByDealerPortinMappingFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getNetworkByDealerPortinMappingFailed(error));
    }
}

function* createPortinActivationResponse({ data }) {
    try {
        const response = yield call(addData, '/createPortinActivation', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/getBulkPortinActivation');
            if (responseList.response.Status) {
                    yield put(actions.createPortinActivationSuccess(responseList.response.Data,response.response.Message));
            } else {
                yield put(actions.addDealerFailed( response.response.Message ));
            }

        } else {
            yield put(actions.createPortinActivationFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.createPortinActivationFailed(error));
    }
}


function* getBulkPortinActivationResponse() {
    try {
        const response = yield call(getList, '/getBulkPortinActivation');
        if (response.response.Status) {
            yield put(actions.getBulkPortinActivationSuccess(response.response.Data));
        } else {
            yield put(actions.getBulkPortinActivationFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getBulkPortinActivationFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_NETWORK_BY_DEALER_PORTIN_MAPPING, getPortinNetworkResponse)]);
    yield all([takeEvery(actions.CREATE_PORTIN_ACTIVATION, createPortinActivationResponse)]);
    yield all([takeEvery(actions.GET_BULK_PORTIN_ACTIVATION, getBulkPortinActivationResponse)]);


}